@import "colors";

.custom-checkbox {
  input {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  label {
    font-size: 18px !important;
    padding-left: 10px !important;
    line-height: 28px !important;
    cursor: pointer;
  }

  .form-check-input {
    &:checked {
      background-color: $themeColorMain;
      border-color: darken($themeColorMain, 5%);
    }

    &:focus {
      box-shadow: none;
    }
  }

}