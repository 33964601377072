#article-detail-content {

  table td {
    padding: 0.5rem;
    border: 1px solid #ccc;
  }

  article {
    overflow: auto;
  }

  a {
    color: #6f9b34 !important;
  }
}