.author_section{
  .col-md-2, .col-md-3{
    padding-right: 0px;
    left: 0px;
  }
  .form-control{
    font-size: 14px;

  }
  input:read-only{
    background: #80808038;
  }
}