@import "src/styles/colors";
#editorial_board {
  border: none;
  .single-team {
    padding: 0px;
    position: relative;
    width: 100%;
  }
  .team-thumb {
    display: block;
    width: 100%;
    height: 100px;
    max-width: 100px;
    margin: 0 auto;
  }
  .overlay_content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $themeColorMain;
  }

  .single-team:hover .overlay_content {
    opacity: 1;
    cursor: pointer;
  }

  .content_detail {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}