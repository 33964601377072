.publish_banner{
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;

  .content{
    padding-top: 5rem;
  }
  @media (max-width: 768px) {
    height: 300px;

    .content{
      padding-top: 1rem;
    }
  }
}