.journal_head_menu h1 {
  font-size: 2rem;
  transition: font 0.3s ease
}

.scrollXJournal .sticky-top .journal_head_menu h1 {
  font-size: 1.6rem !important;
  transition: font 0.3s ease
}
@media only screen and (max-width: 768px) {
  .journal_head_menu h1 {
    font-size: 1.5rem !important;
  }
  .scrollXJournal .sticky-top .journal_head_menu h1 {
    font-size: 1rem !important;
  }
}