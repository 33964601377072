.partner_banner{
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;

  .content{
    padding-top: 3rem;
  }
  @media (max-width: 768px) {
    height: 300px;
    .content{
      padding-top: 2rem;
    }
  }
}